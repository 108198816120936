/*
 * Note that geo_drawer needs to be added as an inline js template for it to
 * work. This is purely to trigger the attached js globally. We do not
 * currently make use of the inline mustache template itself. The translated
 * rendered content is retrieved via MantleMultiLang.getTranslatedContent.
 *
 * This is because a users GEO / accept-language might behoove us to return
 * content in a different language then the current locales language.
 *
 * NOTE: Most of the geo drawer logic is in mantle_multilang. This file is to
 * define the brand specific render of the geo drawer.
 */
var generic = generic || {};
var site = site || {};
var MantleMultiLang = MantleMultiLang || {};
var SiteOverlayManager = SiteOverlayManager || {};

(function ($, Drupal) {
  /*
   * Function that handles rendering the geo drawer overlay.
   */
  var launchGeoOverlay = function (content, geo_settings) {
    var overlay_options = {
      content: content,
      fixed: true,
      width: '100%',
      height: 100,
      bottom: 0,
      cssClass: 'geo-drawer-cbox',
      scrolling: false,
      transition: 'none'
    };

    overlay_options.onComplete = function () {
      var $box = $('#colorbox');
      var $content = $('.geo-drawer', $box);
      var $switchLink = $('.js-switch-link', $content);
      var $icon = $('.js-region-icon', $content);
      var $stayHereLink = $('.js-stay-here', $content);
      var geo_label = geo_settings.region_label;
      var region_icon = geo_settings.region_icon;
      var geo_locale = geo_settings.LOCALE;
      var show_the_prefix = !!geo_settings.prefixed_the;

      // Update visuals based on whether geo country matches a known locale.
      if (geo_settings) {
        // change to proper region names
        $('.js-geo-region', $content).html(geo_label);
        if (!!region_icon) {
          $icon.attr('src', region_icon);
        }
        $content.removeClass('geo-missing');

        // Some languages need "the" prefix
        if (show_the_prefix) {
          $('.js-the-prefix', $content).show();
        } else {
          $('.js-the-prefix', $content).hide();
        }

        // Change non-crossborder to just a link
        if (geo_settings.non_crossborder && geo_settings.base_url) {
          $switchLink.attr('href', geo_settings.base_url);
        }
      } else {
        // this triggers css that hides and shows
        $content.addClass('geo-missing');
      }

      $stayHereLink.on('click', function (e) {
        e.preventDefault();
        $.colorbox.close();
      });

      $switchLink.on('click', function (e) {
        // setGeoLock so we don't run this twice.
        MantleMultiLang.setGeoLock();

        // Non crossborder just acts like a link.
        if (geo_settings && geo_settings.non_crossborder && geo_settings.base_url) {
          return;
        }

        e.preventDefault();

        if (geo_locale) {
          MantleMultiLang.requestLocaleSwitch(geo_locale);
        }
      });

      // wait a bit to resize and then reveal.
      window.setTimeout(function () {
        $.colorbox.resize();
        $box.addClass('show');
      }, 300);
    };

    overlay_options.onClosed = function () {
      // setGeoLock so we don't run this twice.
      MantleMultiLang.setGeoLock();
    };

    // Launch overlay
    var meta = {
      id: 'geo-drawer',
      weight: 10
    };

    SiteOverlayManager.requestOverlay(overlay_options, meta);
  };

  Drupal.behaviors.geoDrawerV1 = {
    attach: function (context) {
      var options = {
        render: function (data, geo_settings) {
          var content = data.content;

          launchGeoOverlay(content, geo_settings);
        }
      };

      var $intPageFormatter = $('.js-international-page-formatter', context);
      var urlString = $intPageFormatter.data('url');
      var pathname = $(location).attr('pathname');
      var stringMatched = pathname.indexOf(urlString) !== -1;

      // Only run the geo drawer based on conf
      var geoDrawerEnabled = Drupal.settings.common && Drupal.settings.common.geo_drawer_enabled;

      if (geoDrawerEnabled && !stringMatched) {
        MantleMultiLang.runGeoDrawerLogic(options, 'geo_drawer');
      }
    }
  };
})(jQuery, Drupal);
